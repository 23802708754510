import _ from 'lodash';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// const apiBaseUrl = 'http://localhost:5001/assist-fe04e/us-central1';
const apiBaseUrl = 'https://us-central1-assist-fe04e.cloudfunctions.net';

function handleJsonResult(result) {
  let jsonResponse = false;
  let returnObject;
  try {
    returnObject = result.json();
    jsonResponse = true;
  } catch {
    returnObject = result;
  }

  if (result.status === 200) {
    if (jsonResponse) {
      return returnObject;
    } else {
      return Promise.resolve({});
    }
  } else {
    if (jsonResponse) {
      // If the operation failed, we want to make sure it's handled by the 'catch' function set by
      // the caller.
      return returnObject.then((result) => {
        return Promise.reject(result);
      });
    } else {
      return Promise.reject(returnObject);
    }
  }
}

function toDollars(amount) {
  return '$' + parseFloat(amount).toFixed(2);
}

const api = {
  getPayoffPlan: async (debts, totalMonthlyDebtPayoffBudget) => {
    let result = await fetch(
      apiBaseUrl + '/payoffPlan',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          debts: debts,
          totalMonthlyDebtPayoffBudget: totalMonthlyDebtPayoffBudget,
        }),
      }
    );

    return handleJsonResult(result);
  },
  signUp: async (signUpEmail, planId) => {
    let result = await fetch(
      apiBaseUrl + '/joinWaitlist',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: signUpEmail,
          planId: planId
        }),
      }
    );

    return handleJsonResult(result);
  }
};

export default class Payoff extends React.Component {
  createDefaultDebt = () => {
    return {
      accountName: undefined,
      accountType: 'Credit Card',
      currentBalance: undefined,
      minimumMonthlyPayment: undefined,
      annualInterestRate: undefined,
      hidden: false
    };
  };

  state = {
    showSignUpModal: false,
    planCreationInFlight: false,
    planCreationErrorMessage: undefined,
    signUpInFlight: false,
    signUpErrorMessage: undefined,
    signUpCompleted: false,
    showDebts: true,
    showPlan: false,
    plan: undefined,
    planId: undefined,
    startingBalance: undefined,
    totalPrincipalPaid: undefined,
    totalInterestPaid: undefined,
    debtFreeDate: undefined,
    debts: [this.createDefaultDebt()],
    totalMonthlyDebtPayoffBudget: undefined,
    signUpEmail: undefined
  };

  addDefaultDebt = () => {
    this.setState({debts: this.state.debts.concat(this.createDefaultDebt())});
  };

  calculatePayoffPlan = (event) => {
    this.setState({
      planCreationInFlight: true,
      planCreationErrorMessage: undefined
    });

    api.getPayoffPlan(this.state.debts, this.state.totalMonthlyDebtPayoffBudget)
      .then((result) => {
        let plan = result.plan;
        let planId = result.planId;

        let startingBalance = plan[0].startingBalance;
        let totalPrincipalPaid = _.sumBy(plan, 'principalPaid');
        let totalInterestPaid = _.sumBy(plan, 'interestPaid');
        let debtFreeDate = plan[plan.length - 1].month;

        this.setState({
          planCreationInFlight: false,
          showDebts: false,
          showPlan: true,
          plan: plan,
          planId: planId,
          startingBalance: startingBalance,
          totalPrincipalPaid: totalPrincipalPaid,
          totalInterestPaid: totalInterestPaid,
          debtFreeDate: debtFreeDate
        });

        this.scrollToPayoffPlanRef();
      })
      .catch(() => {
        this.setState({
          planCreationInFlight: false,
          planCreationErrorMessage: 'Please double check the debts and try again'
        })
      });

    event.preventDefault();
    event.stopPropagation();
  };

  canCalculatePayoffPlan = () => {
    let areAllDebtsValid = true;

    if (this.state.debts.length < 1) {
      areAllDebtsValid = false;
    }

    _.forEach(this.state.debts, (debt) => {
      if (!_.isString(debt.accountName) || debt.accountName.length < 1) {
        areAllDebtsValid = false;
      }

      if (_.isNaN(parseFloat(debt.currentBalance)) || parseFloat(debt.currentBalance) < 0.01) {
        areAllDebtsValid = false;
      }
    });

    return areAllDebtsValid;
  };

  toggleDebt = (index) => {
    let debts = _.cloneDeep(this.state.debts);
    debts[index].hidden = !debts[index].hidden;
    this.setState({debts: debts});
  };

  getClassNameForDebt = (index) => {
    if (this.state.debts[index].hidden) {
      return 'collapse';
    } else {
      return '';
    }
  };

  getCaretIcon = (index) => {
    if (this.state.debts[index].hidden) {
      return faCaretDown;
    } else {
      return faCaretUp;
    }
  };

  deleteDebt = (index) => {
    let debts = _.cloneDeep(this.state.debts);
    debts.splice(index, 1);
    this.setState({debts: debts});
  };

  updateDebt = (index, debt) => {
    let debts = _.cloneDeep(this.state.debts);
    debts.splice(index, 1, debt);
    this.setState({debts: debts});
  };

  getTotalMinimumMonthlyPayments = () => {
    return _.sumBy(this.state.debts, (debt) => {
      return debt.minimumMonthlyPayment || 0;
    });
  };

  getMinimumPayoffBudget = () => {
    return Math.max(this.getTotalMinimumMonthlyPayments(), 0.01);
  };

  editDebts = () => {
    this.setState({
      showDebts: true,
      showPlan: false
    })
  };

  setShowSignUpModal = (state) => {
    this.setState({
      showSignUpModal: state
    })
  };
  handleCloseSignUpModal = () => this.setShowSignUpModal(false);
  handleShowSignUpModal = () => this.setShowSignUpModal(true);

  signUp = (event) => {
    this.setState({
      signUpInFlight: true,
      signUpErrorMessage: undefined
    });

    api.signUp(this.state.signUpEmail, this.state.planId)
      .then((result) => {
        this.setState({
          signUpInFlight: false,
          signUpCompleted: true
        });
      })
      .catch(() => {
        this.setState({
          signUpInFlight: false,
          signUpErrorMessage: 'Please double check the email address and try again'
        })
      });

    event.preventDefault();
    event.stopPropagation();
  };

  payoffPlanRef = React.createRef();
  scrollToPayoffPlanRef = () => this.payoffPlanRef.current.scrollIntoView();

  render() {
    return (
      <>
        <nav class="navbar navbar-light bg-light">
          <div class="container">
            <a class="navbar-brand" href="https://withassist.com">
              <img src="./logo_text128.png" alt="logo" height="64" />
            </a>
          </div>
        </nav>

        <div class="container">
          <div class="alert alert-dismissible mt-3" style={{background: "linear-gradient(175deg, rgba(111,168,220,1) 0%, rgba(12,83,148,1) 100%)", color: '#fff'}}>
            <div class="row">
              <div class="col-12 col-sm-9">
                <h1 class="display-4">
                  Want to know when you'll be debt free?
                </h1>
                <p class="lead">
                  The first step towards debt freedom is a payoff plan. Learn who to pay, when and how
                  much until you're debt free.
                </p>
                <hr class="my-4"></hr>
                <p>
                  <i>Assist</i> will help you create your personalized plan. It's ok if you don't have
                  all the information available right now—we'll make adjustments to the plan over time.
                </p>
              </div>

              <div class="col-12 col-sm-3 text-center">
                <a href="https://www.withassist.com">
                  <img src="./dashboard.png" height="300" alt="Assist - Dashboard" />
                </a>
              </div>
            </div>

            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>

          { this.state.showDebts && (
            <>
              <div class="mb-3 mt-3">
                <h4>
                  Enter all the debts that you're aware of (credit cards, auto loans, student loans,
                  medical bills or others)—except for mortgage:
                </h4>
              </div>

              <form class="needs-validation" onSubmit={this.calculatePayoffPlan}>
                <div class="mb-3">
                  { _.map(this.state.debts, (debt, index) => {
                    return (
                      <>
                        <div class="mb-3">
                          <div class="mb-3">
                            <div class="row">
                              <div class="col-11">
                                Debt {index + 1}{ debt.hidden && _.isString(debt.accountName) && debt.accountName.length > 1 && (
                                  <>
                                    : {debt.accountName}
                                  </>
                                )}
                              </div>
                              <div class="col-1 text-end">
                                <FontAwesomeIcon
                                  icon={this.getCaretIcon(index)}
                                  onClick={() => this.toggleDebt(index)}></FontAwesomeIcon>
                              </div>
                            </div>
                          </div>
                          <div className={this.getClassNameForDebt(index)}>
                            <div class="row mb-3">
                              <div class="col-6">
                                <label class="form-label">Account Name</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="e.g. Credit Card"
                                  value={debt.accountName}
                                  onChange={(event) => {
                                    debt.accountName = event.target.value;
                                    this.updateDebt(index, debt);
                                  }}
                                  minLength={1}
                                  required></input>
                              </div>
                              <div class="col-6 text-end">
                                <button class="btn btn-link p-0" onClick={() => this.deleteDebt(index)}>Delete this debt</button>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-6">
                                <label class="form-label">Account Type</label>
                                <select
                                  class="form-select"
                                  value={debt.accountType}
                                  onChange={(event) => {
                                    debt.accountType = event.target.value;
                                    this.updateDebt(index, debt);
                                  }}
                                  required>
                                  <option name="Credit Card">Credit Card</option>
                                  <option name="Auto Loan">Auto Loan</option>
                                  <option name="Student Loan">Student Loan</option>
                                  <option name="Medical Bill">Medical Bill</option>
                                  <option name="Personal Loan">Personal Loan</option>
                                  <option name="Other">Other</option>
                                </select>
                              </div>
                              <div class="col-6">
                                <label class="form-label">Current Balance</label>
                                <div class="input-group">
                                  <span class="input-group-text">$</span>
                                  <input
                                    type="number"
                                    class="form-control"
                                    placeholder="e.g. 10000"
                                    value={debt.currentBalance}
                                    onChange={(event) => {
                                      debt.currentBalance = parseFloat(event.target.value);
                                      this.updateDebt(index, debt);
                                    }}
                                    min="0.01"
                                    step="0.01"
                                    required></input>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-6">
                                <label class="form-label">Minimum Monthly Payment</label>
                                <div class="input-group">
                                  <span class="input-group-text">$</span>
                                  <input
                                    type="number"
                                    class="form-control"
                                    placeholder="e.g. 50"
                                    value={debt.minimumMonthlyPayment}
                                    onChange={(event) => {
                                      debt.minimumMonthlyPayment = parseFloat(event.target.value);
                                      this.updateDebt(index, debt);
                                    }}
                                    min="0"
                                    step="0.01"></input>
                                </div>
                                <div class="form-text">Optional</div>
                              </div>
                              <div class="col-6">
                                <label class="form-label">Annual Interest Rate (APR)</label>
                                <div class="input-group">
                                  <input
                                    type="number"
                                    class="form-control"
                                    placeholder="e.g. 17.50"
                                    value={debt.annualInterestRate}
                                    onChange={(event) => {
                                      debt.annualInterestRate = parseFloat(event.target.value);
                                      this.updateDebt(index, debt);
                                    }}
                                    min="0"
                                    step="0.01"></input>
                                  <span class="input-group-text">%</span>
                                </div>
                                <div class="form-text">Optional</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        { (this.state.debts.length > 1) &&
                          <hr></hr>
                        }
                      </>
                    );
                  })}

                  <button
                    type="button"
                    class="btn btn-link p-0 mb-3"
                    onClick={this.addDefaultDebt}>Add debt</button>
                </div>

                <hr></hr>

                <div>
                  <label class="form-label">What's your monthly budget for debt payoff?</label>
                  <div class="input-group">
                    <span class="input-group-text">$</span>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="e.g. 100"
                      value={this.state.totalMonthlyDebtPayoffBudget}
                      onChange={(event) => {
                        this.setState({
                          totalMonthlyDebtPayoffBudget: parseFloat(event.target.value)
                        });
                      }}
                      min={this.getMinimumPayoffBudget()}
                      step="0.01"
                      required></input>
                  </div>
                  <div class="form-text">Your minimum monthly payments total to {toDollars(this.getTotalMinimumMonthlyPayments())}</div>
                </div>

                <hr></hr>

                <div class="mb-3">
                  <button
                    type="submit"
                    class="btn btn-lg btn-primary"
                    disabled={this.state.planCreationInFlight}>
                    { !this.state.planCreationInFlight && (
                      <>
                        Create Plan
                      </>
                    )}

                    { this.state.planCreationInFlight && (
                      <>
                        Please wait...
                      </>
                    )}
                  </button>
                </div>

                { !_.isUndefined(this.state.planCreationErrorMessage) && (
                  <span class="text-danger">{this.state.planCreationErrorMessage}</span>
                )}
              </form>
            </>
          )}

          { this.state.showPlan && (
            <>
              <div
                class="alert mb-3"
                style={{background: 'rgba(111,168,220,0.1)'}}
                ref={this.payoffPlanRef}>
                <div class="row">
                  <div class="col-6">
                    <h1 class="display-6">Your Payoff Plan</h1>
                  </div>
                  <div class="col-6 text-end">
                    <button
                      class="btn btn-link"
                      onClick={this.editDebts}>
                      Edit debts
                    </button>
                  </div>
                </div>

                <hr class="my-4"></hr>

                <p class="lead">Debt free date: {this.state.debtFreeDate}</p>
                <p class="lead">Starting balance: {toDollars(this.state.startingBalance)}</p>
                <p class="lead">Total principal paid: {toDollars(this.state.totalPrincipalPaid)}</p>
                <p class="lead">Total interest paid: {toDollars(this.state.totalInterestPaid)}</p>

                <hr class="my-4"></hr>

                <div class="row">
                  <div class="col-12 col-sm-6">
                    <Button size="lg" variant="primary" onClick={this.handleShowSignUpModal}>
                      Track This Plan With <i>Assist</i>
                    </Button>

                    <div class="mt-3">
                      <p>6 great reasons to start with <i>Assist</i> today:</p>
                      <ol>
                        <li>Become debt free as soon as you can</li>
                        <li>Follow a plan that adapts to your financial abilities</li>
                        <li>Get help with budgeting</li>
                        <li>Work with others to stay motivated</li>
                        <li>Learn to be frugal and find opportunities to increase income and cut expenses</li>
                        <li>Earn rewards while getting out of debt</li>
                      </ol>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6 text-center">
                    <img src="./schedule.png" height="300" alt="Assist - Schedule" />
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <h4>Your starter payoff schedule:</h4>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Month</th>
                      { _.map(this.state.plan[0].payments, (payment) => {
                        return (
                          <th scope="col">{payment.debtName}</th>
                        );
                      })}
                      <th scope="col" class="d-none d-sm-table-cell">Snowball Amount</th>
                      <th scope="col" class="d-none d-sm-table-cell">Running Total Paid</th>
                      <th scope="col" class="d-none d-sm-table-cell">Principal Paid</th>
                      <th scope="col" class="d-none d-sm-table-cell">Interest Paid</th>
                      <th scope="col" class="d-none d-sm-table-cell">Starting Balance</th>
                      <th scope="col" class="d-none d-sm-table-cell">Total Payments</th>
                      <th scope="col" class="d-none d-sm-table-cell">Total Minimum Payments</th>
                    </tr>
                  </thead>
                  <tbody>
                    { _.map(this.state.plan, (planMonth, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td class="table-primary">{planMonth.month}</td>
                          { _.map(planMonth.payments, (payment) => {
                            let className;
                            if (payment.paymentAmount > 0) {
                              className = 'table-primary';
                            }

                            return (
                              <td className={className}>{toDollars(payment.paymentAmount)}</td>
                            );
                          })}
                          <td class="d-none d-sm-table-cell">{toDollars(planMonth.snowballAmount)}</td>
                          <td class="d-none d-sm-table-cell">{toDollars(planMonth.runningTotalPaid)}</td>
                          <td class="d-none d-sm-table-cell">{toDollars(planMonth.principalPaid)}</td>
                          <td class="d-none d-sm-table-cell">{toDollars(planMonth.interestPaid)}</td>
                          <td class="d-none d-sm-table-cell">{toDollars(planMonth.startingBalance)}</td>
                          <td class="d-none d-sm-table-cell">{toDollars(planMonth.totalPayments)}</td>
                          <td class="d-none d-sm-table-cell">{toDollars(planMonth.totalMinimumPayments)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>

        <Modal show={this.state.showSignUpModal} onHide={this.handleCloseSignUpModal}>
          <form class="needs-validation" onSubmit={this.signUp}>
            <Modal.Header closeButton>
              <Modal.Title>Sign Up To <i>Assist</i></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              { !this.state.signUpCompleted && (
                <>
                  <div class="mb-3">
                      <i>Assist</i> is currently an invite-only app. Sign up to be the first to know
                      when new invites are available.
                  </div>

                  <div>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      value={this.state.signUpEmail}
                      onChange={(event) => {
                        this.setState({
                          signUpEmail: event.target.value
                        });
                      }}
                      required></input>
                  </div>
                </>
              )}

              { this.state.signUpCompleted && (
                <>
                  <div class="mb-3">
                    Thank you for your interest! We'll email you when new invites are available.
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseSignUpModal}>
                Close
              </Button>

              { !this.state.signUpCompleted && (
                <Button type="submit" variant="primary" disabled={this.state.signUpInFlight}>
                  { !this.state.signUpInFlight && (
                    <>
                      Sign Up
                    </>
                  )}

                  { this.state.signUpInFlight && (
                    <>
                      Please wait...
                    </>
                  )}
                </Button>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}
